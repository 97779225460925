<template>
  <div class="article" v-if="canAdd">
    <page-header title="Ajout d'un jeu" icon="fa fa-dice" >
    </page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <article-editor
      :article="article"
      @done="onArticleCreated($event)"
      @cancel="onCancel"
    ></article-editor>
  </div>
</template>

<script>
// @ is an alias to /src
import ArticleEditor from '@/components/Store/ArticleEditor.vue'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import { makeStoreArticle } from '@/types/store'

export default {
  name: 'add-article',
  props: {
    youthHome: [String, Number],
  },
  mixins: [BackendMixin],
  components: {
    ArticleEditor,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      article: makeStoreArticle(),
      loadingName: 'add-article',
    }
  },
  async created() {
  },
  watch: {
    article: function(newValue, oldValue) {},
  },
  computed: {
    canAdd() {
      return this.article && this.hasPerm('store.add_article')
    },
  },
  methods: {
    onArticleCreated(event) {
      router.push(this.getLinkToArticle(event.article))
    },
    onCancel() {
      router.push(this.getLinkToArticlesList())
    },
    getLinkToArticle(article) {
      return { name: 'store-article-detail', params: { articleId: '' + article.id, }, }
    },
    getLinkToArticlesList() {
      return { name: 'store-articles', }
    },
  },
}
</script>

<style lang="less">
</style>
